<template>
  <div style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class="d-flex flex-row">
          <h1>Purchasing</h1>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-row align-center">
          <h2>{{this.plural}}</h2>
          <v-btn
            class="mx-2"
            fab
            small
            dark
            color="indigo"
            @click="createA"
          >
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <h3>Suppliers ({{suppliers.length}})</h3>
          <v-text-field outlined label="Search Suppliers" v-model="groupSearch" @input="searchGroup"/>
          <div style="height:900px; overflow: scroll;" class="d-flex flex-column">
            <div style="padding: 10px; background-color:rgba(0,0,0,0.09); margin-bottom: 3px; border-radius: 7px;" class="d-flex flex-row align-center justify-space-between" v-for="supplier in filteredGroup" :key="supplier.id">
              <span>{{supplier.name}}</span>
              <v-btn @click="loadPOsFromSupplier(supplier.id, supplier.name)" fab x-small color="info"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </div>
          </div>
        </v-col>
        <v-col cols="10">
          <v-card>
            <v-card-title>
              {{tableTitle}}
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="data"
                :items-per-page="-1"
                class="elevation-1"
                @click:row="rowClick"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="createDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          Create New Purchase Order
        </v-card-title>
        <v-card-actions>
          <v-btn color="error" text @click="cancelCreate()">
            Cancel
          </v-btn>
          <v-btn color="success" @click="createB">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import moment from 'moment';
  import { mapGetters } from "vuex"
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        snack: false,
        snackColor: '',
        snackText: '',
        tableTitle: '',
        max25chars: v => v.length <= 25 || 'Input too long!',
        headers: [
          { text: '#', align: 'start', sortable: true, value: 'id' },
          { text: 'Date Created', align: 'start', sortable: true, value: 'createdAt' },
          { text: 'Date Signed', align: 'start', sortable: true, value: 'sealedDocument.sealedOn' },
          { text: 'Signed By', align: 'start', sortable: true, value: 'sealedDocument.sealedBy' },
          { text: 'Supplier', align: 'start', sortable: true, value: 'supplierInfo.name' },
          { text: 'Status', align: 'start', sortable: true, value: 'status' },
        ],
        editMode: false,
        data: [],

        groupSearch: "",
        filteredGroup: [],

        suppliers: [],
        search: [],
        singular: "Purchase Order",
        singularLower: "puchaseorder",
        plural: "Purchase Orders",
        pluralLower: "purchaseorders",
        createDialog: {
          isOpen: false,
          confirmed: false
        }
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/suppliers`);
          if(res.data.error) throw res.data.error
          this.suppliers = res.data.data;
          this.filteredGroup = this.suppliers;

          if(this.$route.query.supplier){
            let id = parseInt(this.$route.query.supplier);
            let supplier = this.suppliers.find(x => x.id===id);
            if(supplier){
              await this.loadPOsFromSupplier(id, supplier.name)
            }
          }
        } catch (error) {
            console.error(error);
        } finally {
            this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getId', 'getEndpoint'])
    },
    methods: {
      rowClick(row){
        if(row.status === 1) 
          this.$router.push({ path: `/${this.pluralLower}/view/${row.id}`})
        else
          this.$router.push({ path: `/${this.pluralLower}/create/${row.id}`})
      },
      createA(){
        this.createDialog.isOpen = true
      },
      async createB(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}`)
          if(res.data.error) throw res.data.error  
          this.createDialog.isOpen = false
          await this.$router.push({ path: `/${this.pluralLower}/create/${res.data.data.id}`})
        } catch (error) {
         console.log(error)
         this.createDialog.isOpen = false
        }
      },
      cancelCreate(){
        this.createDialog.isOpen = false
      },
      createRR(){
        this.$router.push({ path: `/receivingreports/create`})
      },
      async loadPOsFromSupplier(id, name){
        let res = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/bySupplier/${id}`)
        this.data = res.data.data
        this.data.forEach(x=>{
          if(x.sealedDocument){
            x.sealedDocument.sealedOn = moment(x.sealedDocument.sealedOn).format("DD/MM/YYYY hh:mm:ss")
          }
          x.createdAt = moment(x.createdAt).format("DD/MM/YYYY hh:mm:ss")
        });

        this.tableTitle = `Showing ${this.data.length} ${this.data.length===1?this.singular : this.plural} from ${name}`

        if(!(this.$route.query.supplier && this.$route.query.supplier==id)){
          await this.$router.replace({path: `/purchasing`, query:{supplier: id}});
        }
      },
      searchGroup(){
        let arr = this.suppliers;
        let term = "name";

        if(this.groupSearch.length===0){
          this.filteredGroup = arr;
          return;
        }

        this.filteredGroup = arr.filter(item => {
          return item[term].toLowerCase().includes(this.groupSearch.toLowerCase());
        });
      }
    }
  }
</script>
